import { useCallback, useRef, useState } from "react";
import "./App.scss";
import { Emoji } from "./Emoji";
import { Circle } from "./Circles";
import { Howl } from "howler";
import { DndContext, DragEndEvent, useSensors } from "@dnd-kit/core";
import { MouseSensor, TouchSensor, useSensor } from "@dnd-kit/core";

const Emojis = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

type CircleState = {
  index: number;
  emojiIndex: number | null;
};

function App() {
  const sound = useRef<Howl | null>(null);

  const [gameStarted, setGameStarted] = useState(false);

  // This is to overcome the limitation that Howler doesn't play when `touch-events` is set to `none` as recommended by dnd-core
  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const sensors = useSensors(mouseSensor, touchSensor);

  const initialState = useCallback(() => {
    return Array.from({ length: 10 }, (_, i) => i + 1).map((index) => {
      return { index: index, emojiIndex: null };
    }) as CircleState[];
  }, []);

  const [circles, setCircles] = useState(() => initialState());

  const handleSound = useCallback((emojiIndex: number) => {
    if (sound.current) sound.current.stop();

    sound.current = new Howl({
      src: [`soundtracks/${emojiIndex}.m4a`],
      html5: true,
    });

    sound.current.play();
  }, []);

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (!over?.data.current) return;
      const currentCircle = over.data.current;

      if (!active.data.current) return;
      const currentEmoji = active.data.current;

      handleSound(currentEmoji.index);

      setCircles(
        circles.map((circle) =>
          circle.index === currentCircle.index
            ? { ...circle, emojiIndex: currentEmoji.index }
            : { ...circle }
        )
      );
    },
    [circles, handleSound]
  );

  if (!gameStarted) {
    return (
      <button
        className="start-button"
        onClick={() => {
          // Workaround for iOS devices.
          // Sound is only initiated on user action and for some reason the first draggable emoji doesn't trigger a sound.
          // That is why we put a big start button which triggers empty sound.
          const howler = new Howl({
            src: [`soundtracks/1.m4a`],
            html5: true,
            volume: 0,
            mute: true,
          });
          howler.play();

          setGameStarted(true);
        }}
      >
        START
      </button>
    );
  }

  return (
    <DndContext onDragEnd={handleDragEnd} sensors={sensors}>
      <div className="App">
        <div className="circles">
          {circles.map((circle) => {
            return (
              <Circle
                key={circle.index}
                dropZoneIndex={circle.index}
                emojiIndex={circle.emojiIndex}
              />
            );
          })}
        </div>
        <div className="emoji-list">
          {Emojis.map((emojiIndex) => (
            <Emoji key={emojiIndex} name={emojiIndex} />
          ))}
        </div>
      </div>
    </DndContext>
  );
}

export default App;
