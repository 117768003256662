import { useDraggable } from "@dnd-kit/core";

interface IProps {
  name: number;
}

export const Emoji = (props: IProps) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: `emoji-${props.name}`,
    data: {
      index: props.name,
    },
  });

  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined;

  return (
    <div
      className="emoji"
      style={style}
      ref={setNodeRef}
      {...listeners}
      {...attributes}
    >
      <div>{props.name}</div>
      <img src={`emojis/${props.name}.png`} alt="emoji"></img>
    </div>
  );
};
